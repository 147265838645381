import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import BannerCRO from "../components/BannerCRO";

const LandingCRO = (props) => {

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://brandinavian.com/#organization",
        name: "Brandinavian",
        url: "https://brandinavian.com/",
        sameAs: [
          "https://www.facebook.com/brandinavian/",
          "https://www.instagram.com/brandinavian/",
          "https://www.linkedin.com/company/brandinavian/",
          "https://twitter.com/brandinavian",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "https://brandinavian.com/#logo",
          inLanguage: "en-US",
          url:
          "https://cdn.brandinavian.com/wp-content/uploads/2020/09/brandinavian-logo.png",
          width: 1112,
          height: 116,
          caption: "Brandinavian | Modern Web Development",
        },
        image: { "@id": "https://brandinavian.com/#logo" },
      },
      {
        "@type": "WebSite",
        "@id": "https://brandinavian.com/#website",
        url: "https://brandinavian.com/",
        name: "Brandinavian",
        description: "USA Web Developer",
        publisher: { "@id": "https://brandinavian.com/#organization" },
        inLanguage: "en-US",
      },
      {
        "@type": "WebPage",
        "@id": "https://brandinavian.com/cro/#webpage",
        url: "https://brandinavian.com/cro/",
        name: "CRO | Brandinavian",
        isPartOf: { "@id": "https://brandinavian.com/#website" },
        description:"Retain the visitors on your website and transform them into loyal acquaintances. Get started with Brandinavian today!",
        breadcrumb: {
          "@id":
            "https://brandinavian.com/cro/#breadcrumb",
        },
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://brandinavian.com/cro"],
          },
        ],
      },
      {
        "@type": "BreadcrumbList",
        "@id":
          "https://brandinavian.com/cro/#breadcrumb",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/",
              url: "https://brandinavian.com/",
              name: "Web developer",
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            item: {
              "@type": "WebPage",
              "@id": "https://brandinavian.com/cro",
              url: "https://brandinavian.com/cro",
              name: "Modern web development",
            },
          },
        ],
      },
    ],
  };

return(
  <Layout altLogo={"black"}>
      <SEO
     title="CRO | Brandinavian"
     schemaMarkup={schema}
     lang="en-US"
     description="Retain the visitors on your website and transform them into loyal acquaintances. Get started with Brandinavian today!"
   />
      <BannerCRO />
      <div id="main" className="alt darkmode">
        <section className="pre-article dark">
          <li className="tag">Conversion Rate Optimization</li>
        </section>
        <section id="one" className="article-section main-service">
          <div className="inner article service-article">
            <header className="major">
              <h2>"Will it blend?"</h2>
            </header>
            <p>
            That is the question. Because what is the point of your website, if it cannot excel at it's intended purpose: To eventually make people engage with you in some fashion? Make them press that button, or submit that email? This is a problem that many websites and business owners face, in todays world of 2020. <a
                     href="https://journals.openedition.org/oeconomia/1104?lang=en"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >A world where attention is becoming a scarcer resource every day</a>. As users, we need to be presented with value propositions quickly. We don't want to figure out complex navigation menus - and we don't want to fill out contact forms with seven fields. We need a well-communicated, frictionless experience that will make us subscribe to your service.
            </p>
            <div className="dark-mode-image yellow">
              <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>What is CRO?</h2>
            </header>
            <p>
            The discipline of <b>Conversion Rate Optimization</b> is concerned with ensuring that your website as a funnel, is performing optimally. The goal is to increase the consistency, with which the visitors of your website perform a desired action - submitting an email or signing up for a trial, for example.<br/><br/> The way that we can approach CRO, is through <b>capturing data</b> from our users, so that we can analyze this data with the intention of creating a better journey for them. This is done through <b>meta-analysis</b> of quantitative traffic data from analytics tools, qualitative heatmap data - in combination with surveys and talking to sources that interface with your audience. Behavioural science also plays a role in this process, and there is <a
                     href="https://scholar.google.com/scholar?as_ylo=2020&q=consumer+behavior&hl=en&as_sdt=0,5"
                     target="_blank"
                     rel="noopener"
                     rel="noreferrer"
                   >a wealth of academic papers</a> available now, that go in-depth with consumer behaviour in specific verticals. Armed with this knowledge, we can then form hypotheses about your website - and ultimately put these to the fire through A/B testing.</p>
            <div className="dark-mode-image blue">
              <Img fluid={props.data.imageTwo.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
                   <header className="major">
                    <h2>Diving down the funnel</h2>
                  </header>
                   <p>
                   For a CRO effort to be effective though, we will first need to clearly define what the actual conversion goals of your website are. If you have an eCommerce website for example, it might be tempting to think that it would be most beneficial for you, to simply increase the <b>total number of sales</b> that your store is generating. The truth, however, is that it might be more in the interest of your business to focus on <b>increasing the median value</b> of a sale, depending on what you sell. Quality over quantity.<br/><br/> This is important, because both these cases would definitely require different optimization strategies. So, before you consider embarking on a CRO endeavor - it is critical that you ask yourself this simple, yet key question: <b>What is the best possible action, that a user can perform on my website?</b></p>

           <div className="dark-mode-image green">
              <Img fluid={props.data.imageThree.childImageSharp.fluid} alt="" />
              <li className="tag branded">BRANDINAVIAN</li>
            </div>
            <header className="major">
              <h2>Where I come in</h2>
            </header>
            <p>When thinking about starting a CRO endeavor, there are two general approaches that I recommend. In one scenario, you already have a lot of data that can be analyzed. This is great, since we will have a better a foundation for creating hypotheses about your website and your users. The heirachy of evidence (ranked from high to low validity) typically goes:</p>
            <div className="table-wrapper">
                                    <table className="alt">
                                        <thead>
                                            <tr>
                                                <th>Weight/Validity</th>
                                                <th>Type of evidence</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Highest</td>
                                                <td>Meta-analysis</td>
                                            </tr>
                                            <tr>
                                                <td>Higher</td>
                                                <td>A/B Testing</td>
                                            </tr>
                                            <tr>
                                                <td>High</td>
                                                <td>Data/Science</td>
                                            </tr>
                                            <tr>
                                                <td>Reasonable</td>
                                                <td>User Research</td>
                                            </tr>
                                            <tr>
                                                <td>Reasonable</td>
                                                <td>Expert opinion</td>
                                            </tr>
                                            <tr>
                                                <td>Base</td>
                                                <td>Best Practices</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
            <p>
             As evident - already having data puts us in a position of potentially being able to form hypotheses from the get-go. In the other scenario, where we start from scratch - we will have rely on best practices initially, while we build the validity of our data over time. In any event, I have a track record of sucessfully increasing the conversion rates of high-impact websites, and I can definitely help you too.
            </p>
            <ul className="actions align-center">
              <li>
                <Link to="/contact" className="button">
                  Start optimizing today!
                </Link>
              </li>
            </ul>
          </div>
          <div className="inner article about-author">
            <div className="author-image-box">
              <Img
                className="large-author-image"
                fluid={props.data.imageFour.childImageSharp.fluid}
              />
            </div>
            <div className="author-information">
              <header className="major special">
                <h2 className="large-author-name">Hi, I'm Mads</h2>
              </header>
              <p className="author-biography">
                I'm a Full Stack Engineer and Media Science graduate. Through
                Brandinavian, I help awesome people all over the world,
                designing and engineering digital solutions. I build
                best-in-class, scalable web applications, that convert and
                communicate well.
              </p>
              <p className="author-links" />
              <li className="tag">
                <Link to="/contact">Get in touch</Link>
              </li>
              <li className="tag">
                <Link to="/contact">Request Resume</Link>
              </li>
            </div>
          </div>
        </section>
      </div>
    </Layout>
);
}

export default LandingCRO;

export const croQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "cro-brain.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "funnel.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "drawintable.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageFour: file(relativePath: { eq: "creative-author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

